import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/client";
import { LangItem } from "../../models/lang";
import { UserItem } from "../../models/user";
import { SETLANG } from "../layouts/queries";
import { DataContext } from "../../context/data-context";

const flags = [
  { id: "1", flag: "🇪🇸", name: "Spanish" },
  { id: "2", flag: "🇫🇷", name: "French" },
  { id: "3", flag: "🇩🇪", name: "German" },
];

const LanguageSelect = () => {
  const dataContext = useContext(DataContext);
  const user_local: UserItem = JSON.parse(localStorage.getItem("user") || "{}");
  const settings =
    dataContext.user && dataContext.user.settings
      ? dataContext.user.settings
      : {
          block_lang_selection: false,
        };
  const local_languages: LangItem[] = JSON.parse(
    localStorage.getItem("local_languages") || "{}"
  );
  const languages = local_languages.sort((a, b) =>
    a.id === user_local.lang_id ? -1 : 0
  );
  const active_lang = languages.find((lang) => lang.id === user_local.lang_id);
  const [selectedLanguage, setSelectedLanguage] = useState<LangItem>(
    active_lang || languages[0]
  );
  const [setLang] = useMutation(SETLANG);

  const handleLanguageChange = async (ev: { target: HTMLSelectElement }) => {
    const selected_lang = languages.find((lang) => lang.id === ev.target.value);
    if (!selected_lang) return;
    dataContext.setLoaded(false);
    setSelectedLanguage(selected_lang);
    const set_user = { ...user_local };
    set_user.lang_id = selected_lang.id;
    localStorage.setItem("user", JSON.stringify(set_user));
    try {
      await setLang({ variables: { lang_id: selected_lang.id } });
      window.location.reload();
    } catch (error) {
      dataContext.setLoaded(true);
    }
  };

  if (
    !user_local.is_teacher &&
    settings &&
    settings.block_lang_selection === true
  ) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-md-2 text-left">
        <small className="text-white">Change language:</small> <br />
        <select
          className="custom-select custom-select-lg mb-3 lang-select"
          onChange={handleLanguageChange}
          value={selectedLanguage.id}
        >
          {languages.map((language) => {
            const flag = flags.find((flag) => flag.id === language.id);
            return (
              <option value={language.id} key={`LanguageSelect-${language.id}`}>
                {flag?.flag} {flag?.name}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default LanguageSelect;
