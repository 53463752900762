import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup } from "reactstrap";
import { LangItem } from "../../../models/lang";
import { UPDATELANG, SETHOW } from "../profile/queries";
import { useMutation } from "@apollo/client";
import { UserItem } from "../../../models/user";

const WelcomePage: React.FC = () => {
  const history = useHistory();
  const user_local: UserItem = JSON.parse(localStorage.getItem("user") || "{}");

  const local_languages: LangItem[] = JSON.parse(
    localStorage.getItem("local_languages") || "{}"
  );
  const [langs, setLangs] = useState(
    local_languages.map((l) => ({ ...l, active: false }))
  );
  const [form, setForm] = useState({
    how: "",
    langs: [] as string[],
  });
  const [updateLangs] = useMutation(UPDATELANG);
  const [setHow] = useMutation(SETHOW);

  const updateLangsFun = (ev: { currentTarget: HTMLButtonElement }) => {
    const set_langs = langs.map((l) => ({ ...l }));
    const lang_id = ev.currentTarget.dataset.lang_id;
    const active = ev.currentTarget.dataset.active === "true" ? false : true;
    if (!lang_id) {
      return;
    }
    const index_of = set_langs.findIndex((l: LangItem) => l.id === lang_id);
    if (index_of < 0) {
      return;
    }

    set_langs[index_of].active = active;
    const active_langs = set_langs
      .filter((item) => item.active)
      .map((item) => item.id);
    ///max 2 langs active
    if (active_langs.length > 2) {
      if (!active) {
        setLangs(set_langs);
      }
      return;
    }
    setLangs(set_langs);
    const selected = langs.find((l) => l.active);
    if (!selected) {
      return;
    }
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const type = e.target.id;
    const value = e.target.value;
    const set_form = { ...form, [type]: value };
    setForm(set_form);
  };

  const handleSave = () => {
    if (form.how === "" || form.how === "false") {
    } else {
      setHow({ variables: { how: form.how } });
    }
    const active_langs = langs
      .filter((item) => item.active)
      .map((item) => item.id);
    const set_user = { ...user_local };
    set_user.lang_id = active_langs[0];
    localStorage.setItem("local_languages", JSON.stringify(langs));
    localStorage.setItem("user", JSON.stringify(set_user));
    updateLangs({
      variables: { langs: active_langs, lang_id: active_langs[0] },
    });
    history.push({
      pathname: `/`,
    });
  };

  return (
    <main className="first-container last-container thankyou_page">
      <Helmet>
        <link
          href="https://assets.calendly.com/assets/external/widget.css"
          rel="stylesheet"
        />
        <script
          src="https://assets.calendly.com/assets/external/widget.js"
          type="text/javascript"
          async
        ></script>
      </Helmet>
      <div>
        <h1>Thank you for signing up for Flangoo! </h1>
      </div>
      <div className="my-4">
        <p>What Subject Do You Teach?</p>
        <ButtonGroup>
          {langs && langs.length > 0
            ? langs.map((lang) => (
                <Button
                  size="lg"
                  color={lang.active ? "primary" : "outline-light"}
                  className="no_focus"
                  active={lang.active}
                  key={`Button_${lang.id}`}
                  data-lang_id={lang.id}
                  data-active={lang.active ? "true" : "false"}
                  onClick={updateLangsFun}
                >
                  <i
                    className={`fal mr-2 ${
                      lang.active ? "fa-check-square" : "fa-square"
                    }`}
                  ></i>
                  {lang.name}
                </Button>
              ))
            : null}
        </ButtonGroup>
        <HowDid handleChange={handleChange} how={form.how} />
        <button
          onClick={handleSave}
          className={`btn mb-3 px-5 py-3 ${
            langs.filter((l) => l.active).length === 0
              ? "btn-outline-danger"
              : "btn-danger"
          }`}
          id="save_and_continue"
          disabled={langs.filter((l) => l.active).length === 0}
        >
          Save and Continue
        </button>
        <p
          className={`text-help small ${
            langs.filter((l) => l.active).length === 0 ? "" : "opacity_0"
          }`}
        >
          Please select at least one language
        </p>
        <hr />
        <a
          className=" main_cta btn btn-danger btn-lg"
          href="https://scheduler.zoom.us/d/wk-y_mq7/sign-up-for-a-flangoo-walkthrough-session"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fa fa-calendar-o" aria-hidden="true"></i>
          Schedule a 15-Minute Tour
        </a>
        <p className={`text-help small `}>
          Need help? Schedule a 15-minute tour with us!
        </p>
      </div>
    </main>
  );
};

type HowDidProps = {
  handleChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  how: string;
};

const HowDid: React.FC<HowDidProps> = ({ handleChange, how }) => (
  <>
    <p className="mt-3 mb-0">How did you discover Flangoo? (Optional)</p>
    <div className="black_text form-group">
      <select
        className="form-control"
        name="how"
        id="how"
        onChange={handleChange}
        value={how}
      >
        <option value="false">Select an option</option>
        <option value="email">I got an email</option>
        <option value="facebook">I saw it on Facebook</option>
        <option value="referrer" disabled={false}>
          I got a referrer invitation
        </option>
        <option value="search">Online search</option>
        <option value="colleague">A colleague shared it with me</option>
        <option value="catalog">Saw it in your catalog</option>
        <option value="other">Other</option>
      </select>
    </div>
  </>
);

export default WelcomePage;
