import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Popup } from "../../../models/popups";

type MarketingModalProps = {
  popups: Popup[];
};
const MarketingModal: React.FC<MarketingModalProps> = ({ popups }) => {
  const viewed = JSON.parse(localStorage.getItem("modalView") || "[]");
  const [modal, setModal] = useState(false);
  const [popup, setPopup] = useState<Popup>();

  useEffect(
    () => {
      const popup = popups.find((popup) => !viewed.includes(popup.id));
      if (popup) {
        setPopup(popup);
        setModal(true);
      }
    },
    // eslint-disable-next-line
    [popup]
  );

  const removeModal = () => {
    ////save view in localhost to avoid showing the popup again
    const viewed = JSON.parse(localStorage.getItem("modalView") || "[]");
    viewed.push(popup!.id);
    localStorage.setItem("modalView", JSON.stringify(viewed));
    setModal(false);
  };

  if (!popup) {
    return null;
  }

  return (
    <Modal
      isOpen={modal}
      toggle={removeModal}
      className="bg-white"
      size="ml"
      id="student_modal"
    >
      <ModalHeader toggle={removeModal}>{popup.title}</ModalHeader>
      <img
        src={`${process.env.REACT_APP_IMAGES_URL}/${popup.image}`}
        className="img-fluid  "
        alt="modal"
      />
      <ModalBody>
        <p>{popup.description}</p>
      </ModalBody>
      <ModalFooter style={{ display: "flex", justifyContent: "space-between" }}>
        <button
          type="button"
          className="btn btn-outline-secondary"
          data-dismiss="modal"
          onClick={removeModal}
        >
          {"Close"}
        </button>
        <a
          className="btn px-4 btn-danger text-white"
          data-dismiss="modal"
          href={popup.button_link}
          target="_blank"
          rel="noreferrer"
          onClick={removeModal}
        >
          {popup.button_text}
        </a>
      </ModalFooter>
    </Modal>
  );
};

export default MarketingModal;
