import React from "react";

type WalkthroughProps = {
  saveAchievement: (id: string) => void;
};

const Walkthrough: React.FC<WalkthroughProps> = ({ saveAchievement }) => {
  return (
    <div className="starter_steps mb-3 " id="starter_steps">
      <div className="container first-container">
        <div className="text-center hero-txt">
          <h1>
            <i className="fal fa-gift" aria-hidden="true"></i>
          </h1>
          <h2>Schedule a walkthrough</h2>
          <h4>and get 7 more days!</h4>
          {/*  Calendly link widget begin */}
          <a
            className=" main_cta btn btn-danger btn-lg"
            href="https://scheduler.zoom.us/d/wk-y_mq7/sign-up-for-a-flangoo-walkthrough-session"
            target="_blank"
            rel="noreferrer"
            onClick={() => {
              saveAchievement("15");
            }}
          >
            <i className="fal fa-calendar mr-2" aria-hidden="true"></i>
            Schedule a 15-Minute Tour
          </a>
        </div>
      </div>
    </div>
  );
};

export default Walkthrough;
